@import url('https://fonts.googleapis.com/css2?family=Dongle:wght@700&family=Hind:wght@300;400;500;600;700&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,700;1,200;1,600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

ion-content {
    --background: 'transparent';
    background-image: linear-gradient(45deg, #e37682 15%, #5f4d93 85%);
    background-image: -moz-linear-gradient(45deg, #e37682 15%, #5f4d93 85%);
    background-image: -webkit-linear-gradient(45deg, #e37682 15%, #5f4d93 85%);
    background-image: -ms-linear-gradient(45deg, #e37682 15%, #5f4d93 85%);
}
